import ApiRecordResult from '@/models/APIRecordResult';
import LinktreeLink from '@/models/LinktreeLink';
import axios, { AxiosRequestConfig } from 'axios'

const baseApiUrl = process.env.VUE_APP_FRITTEN_API as string;
const apiClient = axios.create({
    baseURL: baseApiUrl,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'X-Requested-With': 'XMLHttpRequest',
        'Pragma': 'no-cache'
    }
});

export default class FrittenService {
    static SetBearerToken(accessToken: string): void {
        apiClient.defaults.withCredentials = true;
        apiClient.defaults.headers["X-Authorization"] = "Bearer " + accessToken;
    }

    static async GetLinks(): Promise<ApiRecordResult<LinktreeLink>> {
        const response = await apiClient.get<ApiRecordResult<LinktreeLink>>('/records/links?order=ord');
        return response.data;
    }

    static async CreateLink(link: LinktreeLink): Promise<ApiRecordResult<void>> {
        const response = await apiClient.post<ApiRecordResult<void>>('records/links', link);
        return response.data;
    }

    static async DeleteLink(link: LinktreeLink): Promise<ApiRecordResult<void>> {
        const response = await apiClient.delete<ApiRecordResult<void>>('records/links/' + link.id);
        return response.data;
    }

    static async UpdateLink(link: LinktreeLink): Promise<ApiRecordResult<void>> {
        const response = await apiClient.put<ApiRecordResult<void>>('records/links/' + link.id, link);
        return response.data;
    }
}