import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: '#B31B1B',
                secondary: '#491014',
                accent: '#1d1d1b'
            },
        },
    }
});
