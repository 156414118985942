


























import LinktreeLink from "@/models/LinktreeLink";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "link-item" })
export default class LinkItem extends Vue {
    @Prop({ required: true, type: Object })
    readonly item!: LinktreeLink;

    @Prop({ required: false, type: Boolean })
    readonly editable!: boolean;

    get Item(): LinktreeLink {
        return this.item;
    }

    get IsEditable(): boolean {
        return this.editable;
    }

    getImageUrl(link: LinktreeLink): string {
        if (link.url.includes("https://www.facebook.com")) {
            return require("@/assets/img/facebook.png");
        } else if (link.url.includes("https://www.youtube.com")) {
            return require("@/assets/img/youtube.png");
        } else if (link.url.includes("https://www.instagram.com")) {
            return require("@/assets/img/instagram.png");
        } else if (link.url.includes("https://frittenundmeinmaedchen.de")) {
            return require("@/assets/img/fritten.png");
        } else if (link.url.includes("https://fumm.de")) {
            return require("@/assets/img/fritten.png");
        } else {
            return require("@/assets/img/web_white.png");
        }
    }

    getClass(link: LinktreeLink): string {
        if (link.type == 1) {
            return "f-section";
        } else {
            return "f-button";
        }
    }

    deleteButton(event: Event): void {
        event.preventDefault();
        this.$emit("action", "delete", this.Item);
    }

    upButton(event: Event): void {
        event.preventDefault();
        this.$emit("action", "up", this.Item);
    }

    downButton(event: Event): void {
        event.preventDefault();
        this.$emit("action", "down", this.Item);
    }
}
