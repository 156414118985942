

























import LinktreeLink from "@/models/LinktreeLink";
import FrittenService from "@/services/FrittenService";
import LinkItem from "@/components/LinkItem.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        LinkItem
    }
})
export default class Linktree extends Vue {
    Links = new Array<LinktreeLink>();

    mounted(): void {
        FrittenService.GetLinks().then(links => {
            this.Links = links.records;
        });
    }

    gotoHP(): void {
        window.location.href = "https://www.frittenundmeinmaedchen.de";
    }
}
